import types from '../mutation-types';
import Vue from 'vue';
import { TYPE } from 'vue-toastification';

export const initialState = {
  isConnected: false,
  isFirstConnection: true,
};

export const getters = {
  isConnected(state) {
    return state.isConnected;
  },
};

const updateToast = (content, type, timeout, closeable) => {
  Vue.$toast.update(
    'connectivity',
    {
      content,
      options: {
        type: type,
        hideProgressBar: true,
        timeout,
        closeButton: closeable,
        icon: false,
        closeOnClick: closeable,
      },
    },
    true
  );
};

export const actions = {
  async connected({ commit, rootGetters, dispatch }) {
    commit(types.CONNECTED);
    const currentChat = rootGetters.getSelectedChat;
    const messageCount = currentChat?.messages?.length ?? 0;

    if (messageCount <= 0) {
      return;
    }

    const validMessages = currentChat.messages.filter(m => !!m.id);
    await dispatch(
      'fetchNewMessages',
      {
        conversationId: currentChat.id,
        after: validMessages[validMessages.length - 1].id,
      },
      { root: true }
    );

    await dispatch(
      'fetchAllConversations',
      {
        inboxId: rootGetters.getSelectedInbox,
        status: rootGetters.getChatStatusFilter,
      },
      { root: true }
    );


    await dispatch(
      'sendFailedMessages',
      {},
      { root: true }
    );
  },

  async disconnected({ commit }) {
    commit(types.DISCONNECTED);
  },
};

export const mutations = {
  [types.CONNECTED](state) {
    if (!state.isFirstConnection) {
      updateToast('Conexão reestabelecida', TYPE.SUCCESS, 3000, true);
    }
    state.connected = true;
    state.isFirstConnection = false;
  },
  [types.DISCONNECTED](state) {
    if (!state.isFirstConnection) {
      updateToast('Sem conexão com a internet', TYPE.ERROR, false, false);
    }
    state.connected = false;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
