<template>
  <div
    class="conv-header"
    style="
    justify-content: unset;
"
  >
    <div class="user" :class="{ hide: isContactPanelOpen }">
      <Thumbnail
        :src="currentContact.thumbnail"
        size="40px"
        :badge="chatMetadata.channel"
        :username="currentContact.name"
        :status="currentContact.availability_status"
      />
      <div class="user--profile__meta">
        <div style="display: flex">
        <h3 class="user--name text-truncate">
          {{ currentContact.name }}
        </h3>
        <h3 class="assignee-header">
          {{ assigneeName }}
        </h3>
        </div>
        <div style="display: flex">
          <button
            class="user--profile__button clear button small"
            @click="$emit('contact-panel-toggle')"
          >
            {{
              `${$t('CONVERSATION.HEADER.OPEN')} ${$t(
                'CONVERSATION.HEADER.DETAILS'
              )}`
            }}
          </button>
          <button
              class="transcription__button clear button small"
              @click="toggleEmailActionsModal"
          >
            Transcrição
          </button>
          <button
              class="transcription__button clear button small"
              @click="openTicket"
          >
            Ticket #{{ticketId}}
          </button>
        </div>
      </div>
    </div>

    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../Thumbnail';
import EmailTranscriptModal from './EmailTranscriptModal';

export default {
  components: {
    Thumbnail,
    EmailTranscriptModal,
  },

  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentChatAssignee: null,
      showEmailActionsModal: false,
    };
  },

  computed: {
    ...mapGetters({
      agents: 'agents/getVerifiedAgents',
      currentChat: 'getSelectedChat',
    }),

    ticketId() {
      return this.chat.actual_id;
    },

    assigneeName() {
      return this.chat.meta.assignee?.name;
    },

    assigneeEmail() {
      return this.chat.meta.assignee?.email ?? "";
    },
    chatMetadata() {
      return this.chat.meta;
    },

    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chat.meta.sender.id
      );
    },

    assignee() {
      return this.chat.meta.assignee;
    },

    agentList() {
      return [
        {
          confirmed: true,
          name: 'None',
          id: 0,
          role: 'agent',
          account_id: 0,
          email: 'None',
          label: 'None',
        },
        ...this.agents,
      ].map(a => ({
        label: `🏃‍♂️${a.active_conversations_count} - ${a.name}`,
        ...a,
      }));
    },
  },

  methods: {
    openTicket() {
      window.open(`http://10.1.1.209/painel/ticket/novo-busca?&ticketId=${this.ticketId}&attendantEmail=${this.assigneeEmail}`)
    },
    assignAgent(agent) {
      this.$store.dispatch('agentCalls/create', {
        conversation_id: this.currentChat.actual_id,
        callee_id: agent.id,
      });
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.hideConversationActions();
    },

    removeAgent() {},
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignee-header {
  font-size: 1.8rem !important;
  margin-top: 4px !important;
  margin-bottom: auto !important;
  line-height: 1 !important;
  margin-left: 6px !important;
  color: dodgerblue !important;
}

.transcription__button {
  font-size: $font-size-mini;
  margin-top: $space-micro;
  padding: 0;
  margin-left: 4px;
  color: #000a;
}
</style>
