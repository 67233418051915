/* global axios */
import ApiClient from './ApiClient';

class AgentCallsApi extends ApiClient {
  constructor() {
    super('agent_calls', { accountScoped: true });
  }

  create({ callee_id, conversation_id }) {
    return axios.post(`${this.url}`, {
      callee_id,
      conversation_id,
    });
  }

  cancel(callId) {
    return axios.post(`${this.url}/${callId}/cancel`);
  }

  accept(callId) {
    return axios.post(`${this.url}/${callId}/accept`);
  }
}

export default new AgentCallsApi();
