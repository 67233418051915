import { isASubmittedFormMessage } from './MessageTypeHelper';

const shouldShowAvatar = (message, nextMessage) => {
  const currentSender = message.sender?.name ?? '';
  const nextSender = nextMessage.sender?.name ?? '';

  return (
    currentSender !== nextSender ||
    message.message_type !== nextMessage.message_type ||
    isASubmittedFormMessage(nextMessage)
  );
};

export const groupMessagesBySender = conversationsForADate =>
  conversationsForADate.map((message, index) => {
    let showAvatar = false;
    const isLastMessage = index === conversationsForADate.length - 1;
    if (isASubmittedFormMessage(message)) {
      showAvatar = false;
    } else if (isLastMessage) {
      showAvatar = true;
    } else {
      const nextMessage = conversationsForADate[index + 1];
      showAvatar = shouldShowAvatar(message, nextMessage);
    }
    return { showAvatar, ...message };
  });


export default {
  groupMessagesBySender,
  shouldShowAvatar,
};
