import * as types from '../mutation-types';
import Vue from 'vue';
import AgentCallToast from '../../components/widgets/AgentCallToast';
import AgentCallsApi from '../../api/agentCalls';
import { TYPE } from 'vue-toastification';
import moment from 'moment';

export const state = {
  calls: {},
};

export const getters = {
  calls($state) {
    return $state.calls;
  },
  pendingCalls($state) {
    return Object.values($state.calls).filter(c => c.assignment_accepted === null);
  },
};

const showCallToast = (call, timeout, type, closeable) => {
  Vue.$toast.update(
    call.id,
    {
      content: {
        component: AgentCallToast,
        props: {
          agentCall: call,
          type: type,
          timeoutsAt: moment().add(timeout, 'milliseconds'),
        },
      },
      options: {
        type: type,
        id: call.id,
        hideProgressBar: false,
        timeout: false,
        closeButton: closeable,
        icon: false,
        closeOnClick: closeable,
      },
    },
    true
  );
};

const showCallCreatedToast = call =>
  showCallToast(call, call.timeout_in_seconds * 1000, TYPE.DEFAULT, false);
const showCallAcceptedToast = call =>
  showCallToast(call, 5000, TYPE.SUCCESS, true);
const showCallRefusedToast = call =>
  showCallToast(call, 5000, TYPE.ERROR, true);
const showCallTimeoutToast = call =>
  showCallToast(call, 5000, TYPE.ERROR, true);
const hideCallToast = call => Vue.$toast.dismiss(call.id);

export const actions = {
  create: async ({ commit }, { callee_id, conversation_id }) => {
    let { data } = await AgentCallsApi.create({ callee_id, conversation_id });
    commit(types.default.AGENT_CALL_CREATED, data);
  },
  cancel: async ({ commit }, callId) => {
    let { data } = await AgentCallsApi.cancel(callId);
    commit(types.default.AGENT_CALL_ANSWERED, data);
  },
  accept: async ({ commit }, callId) => {
    let { data } = await AgentCallsApi.accept(callId);
    commit(types.default.AGENT_CALL_ANSWERED, data);
  },
  created: async ({ commit }, data) => {
    commit(types.default.AGENT_CALL_CREATED, data);
    showCallCreatedToast(data);
  },
  timeout: async ({ commit }, data) => {
    commit(types.default.AGENT_CALL_TIMEOUT, data);
  },
  answered: async ({ commit }, data) => {
    commit(types.default.AGENT_CALL_ANSWERED, data);
    if (data.assignment_accepted) {
      showCallAcceptedToast(data);
    } else {
      showCallRefusedToast(data);
    }
  },
};

export const mutations = {
  [types.default.AGENT_CALL_CREATED]($state, data) {
    if (data.id) {
      $state.calls[data.id] = data;
    }
  },
  [types.default.AGENT_CALL_TIMEOUT]($state, data) {
    if (data.id) {
      $state.calls[data.id] = data;
    }
  },
  [types.default.AGENT_CALL_ANSWERED]($state, data) {
    if (data.id) {
      $state.calls[data.id] = data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
