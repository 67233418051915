import AuthAPI from '../api/auth';
import BaseActionCableConnector from '../../shared/helpers/BaseActionCableConnector';
import {
  playAgentCallAudio,
  playNewChatAudio,
  playNewMessageAudio,
  stopAgentCallAudio,
} from '../../shared/helpers/AudioNotificationHelper';
import { getSelectedChatConversation } from '../store/modules/conversations/getters';
import { MESSAGE_TYPE } from '../../widget/helpers/constants';
import moment from 'moment';

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubsubToken) {
    super(app, pubsubToken);
    this.CancelTyping = [];
    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'conversation.created': this.onConversationCreated,
      'conversation.opened': this.onStatusChange,
      'conversation.resolved': this.onStatusChange,
      'user:logout': this.onLogout,
      'page:reload': this.onReload,
      'assignee.changed': this.onAssigneeChanged,
      'conversation.typing_on': this.onTypingOn,
      'conversation.typing_off': this.onTypingOff,
      'conversation.contact_changed': this.onConversationContactChange,
      'presence.update': this.onPresenceUpdate,
      'contact_inbox.queue_position_changed': this.onQueuePositionChanged,
      'agent_call.created': this.onAgentCallCreated,
      'agent_call.timeout': this.onAgentCallTimeout,
      'agent_call.answered': this.onAgentCallAnswered,
    };
  }

  onAgentCallCreated = data => {
    this.app.$store.dispatch('agentCalls/created', data);
    let currentUserId = this.app.$store.state.auth.currentUser?.id;

    if (data.callee_id === currentUserId) {
      playAgentCallAudio();
      let intervalId = setInterval(() => {
        let callsObj = this.app.$store.getters['agentCalls/calls'];
        let calls = Object.values(callsObj);

        let pendingCalls = calls.filter((c) => c.assignment_accepted === null);
        let hasValidCalls = pendingCalls.some(c =>
          moment().isBefore(
            moment(c.created_at).add(c.timeout_in_seconds, 'seconds')
          )
        );

        if (!hasValidCalls) {
          stopAgentCallAudio();
          clearInterval(intervalId);
        }
      }, 500);

      if (window.document.visibilityState !== 'visible') {
        this.app.$notification.show(
          'Atendimento',
          {
            body: `${data.callee?.name ?? 'Desconhecido'} quer transferir ${data.conversation?.contact?.name ?? "Desconhecido"} para você`,
            silent: true,
          },
          {
          }
        );
      }
    }
  };

  onAgentCallTimeout = data => {
    this.app.$store.dispatch('agentCalls/timeout', data);
  };

  onAgentCallAnswered = data => {
    this.app.$store.dispatch('agentCalls/answered', data);
  };

  onQueuePositionChanged = data => {
    this.app.$store.dispatch('getConversation', data.id);
  };

  isAValidEvent = data => {
    return this.app.$store.getters.getCurrentAccountId === data.account_id;
  };

  onMessageUpdated = data => {
    this.app.$store.dispatch('updateMessage', data);
  };

  onPresenceUpdate = data => {
    this.app.$store.dispatch('contacts/updatePresence', data.contacts);
    this.app.$store.dispatch('agents/updatePresence', data.users);
    this.app.$store.dispatch('setCurrentUserAvailabilityStatus', data.users);
  };

  onConversationContactChange = payload => {
    const { meta = {}, id: conversationId } = payload;
    const { sender } = meta || {};
    if (conversationId) {
      this.app.$store.dispatch('updateConversationContact', {
        conversationId,
        ...sender,
      });
    }
  };

  onAssigneeChanged = payload => {
    const { meta = {}, id } = payload;
    const { assignee } = meta || {};
    if (id) {
      this.app.$store.dispatch('updateAssignee', { id, assignee });
    }
    this.app.$store.dispatch('agents/get');
    this.fetchConversationStats();
  };

  onConversationCreated = data => {
    this.app.$store.dispatch('addConversation', data);
    this.fetchConversationStats();

    let isAdmin = this.app.$store.getters.getCurrentRole === 'administrator';
    if (isAdmin) {
      playNewChatAudio();

      if (window.document.visibilityState !== 'visible') {
        this.app.$notification.show(
          'Novo atendimento',
          {
            body: `${data.meta.sender.name ?? 'Desconhecido'} entrou no chat`,
            silent: true,
          },
          {
          }
        );
      }
    }
  };

  onLogout = () => AuthAPI.logout();

  onMessageCreated = data => {
    this.app.$store.dispatch('addMessage', data);

    let currentUserId = this.app.$store.state.auth.currentUser?.id;
    let assigneeId = data.conversation_assignee_id;
    let isAssignedToMe = assigneeId === currentUserId;
    let isIncoming = data.message_type === MESSAGE_TYPE.INCOMING;

    let isAdmin = this.app.$store.getters.getCurrentRole === 'administrator';

    if (((isAdmin && !assigneeId) || isAssignedToMe) && isIncoming) {
      playNewMessageAudio();
      if (window.document.visibilityState !== 'visible') {
        this.app.$notification.show(
          'Mensagem',
          {
            body: `${data.sender?.name ?? 'Desconhecido'}: ${data.content}`,
            silent: true,
          },
          {
          }
        );
      }
    }
  };

  onReload = () => window.location.reload();

  onStatusChange = data => {
    this.app.$store.dispatch('updateConversation', data);
    this.fetchConversationStats();
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/create', {
      conversationId,
      user,
    });
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/destroy', {
      conversationId,
      user,
    });
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  fetchConversationStats = () => {
    bus.$emit('fetch_conversation_stats');
  };
}

export default {
  init() {
    if (AuthAPI.isLoggedIn()) {
      const actionCable = new ActionCableConnector(
        window.WOOT,
        AuthAPI.getPubSubToken()
      );
      return actionCable;
    }
    return null;
  },
};
