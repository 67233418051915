/* eslint no-console: 0 */
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import locale from "date-fns/locale/pt-BR";

export default {
  methods: {
    messageStamp(time) {
      const unixTime = fromUnixTime(time);
      return format(unixTime, 'HH:mm', {locale});
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true, locale });
    },
  },
};
