<template>
  <div class="container" style="margin: -8px">
    <span
        class="md-body-1">{{ callerName }} está transferindo {{ conversationString }} para
      {{ calleeName }}</span
    >
    <br/>

    <md-dialog-actions v-if="answer !== null" style="margin-bottom: -12px">
      <md-button v-if="answer" disabled="true" style="color: green">
        Transferencia realizada
      </md-button>
      <md-button v-else disabled="true" style="color: red">
        Transferencia cancelada
      </md-button>
    </md-dialog-actions>
    <md-dialog-actions v-else style="margin-bottom: -12px">
      <md-button
          v-if="isOutgoing"
          class="md-dense md-primary"
          @click.stop="cancelOnClick"
      >
        Cancelar
      </md-button>

      <md-button
          v-if="isIncoming"
          class="md-dense md-primary"
          @click.stop="acceptOnClick"
      >
        Aceitar
      </md-button>
    </md-dialog-actions>
    <progress-bar :bar-color="progressBarColor" :val="timeoutProgress"></progress-bar>
  </div>
</template>

<script >
import $store from 'dashboard/store';
import moment from 'moment';
import ProgressBar from 'vue-simple-progress';
import { TYPE } from "vue-toastification";

export default {
  components: {
    ProgressBar
  },
  props: {
    agentCall: {
      required: true,
    },
    type: {},
    timeoutsAt: {},
  },
  data() {
    return {
      totalTimeout: this.timeoutsAt.diff(moment()),
      currentTimeout: this.timeoutsAt.diff(moment()),
    };
  },
  computed: {
    calls() {
      return $store.getters['agentCalls/calls'];
    },
    answerColor() {
      return `color: ${this.answer ? 'lightgreen' : 'red'}`;
    },
    currentUserId() {
      return $store.getters.getCurrentUserID;
    },
    call() {
      return this.calls[this.agentCall.id];
    },
    isOutgoing() {
      return this.currentUserId === this.call?.caller?.id;
    },
    isIncoming() {
      return this.currentUserId === this.call?.callee?.id;
    },
    caller() {
      return this.call?.caller;
    },
    callerName() {
      return this.isOutgoing
          ? 'Você'
          : this.caller?.display_name ?? this.caller.name;
    },
    callee() {
      return this.call?.callee;
    },
    calleeName() {
      return this.isIncoming
          ? 'Você'
          : this.callee?.display_name ?? this.callee?.name;
    },

    conversation() {
      return this.call?.conversation;
    },
    contact() {
      return this.conversation?.contact;
    },
    conversationString() {
      return `${this.contact?.name} (#${this.conversation?.id})`;
    },
    answer() {
      return this.call?.assignment_accepted;
    },
    progressBarColor() {
      switch (this.type) {
        case TYPE.ERROR:
          return "red";
        case TYPE.SUCCESS:
          return "green";
        default:
          return "#1f93ff";
      }
    },
    timeoutProgress() {
      return this.currentTimeout / this.totalTimeout * 100;
    },
  },
  mounted() {
    let timer = setInterval(() => {
      this.currentTimeout = this.timeoutsAt.diff(moment());
      if (this.currentTimeout < 0) {
        clearInterval(timer);
        this.$emit("close-toast");
      }
    }, 1000);
    setTimeout(() => {
    }, this.totalTimeout);
  },
  methods: {
    cancel(callId) {
      return $store.dispatch('agentCalls/cancel', callId);
    },
    accept(callId) {
      return $store.dispatch('agentCalls/accept', callId);
    },
    cancelOnClick() {
      this.cancel(this.call.id);
    },
    acceptOnClick() {
      this.accept(this.call.id);
    },
  },
};
</script>

<style>
.Vue-Toastification__toast--default,
.Vue-Toastification__toast--success,
.Vue-Toastification__toast--error {
  background-color: white !important;
  color: #1f2d3d !important;
}

.Vue-Toastification__progress-bar {
  background-color: #1f93ff !important;
}

.Vue-Toastification__toast--success .Vue-Toastification__progress-bar {
  background-color: green !important;
}

.Vue-Toastification__toast--error .Vue-Toastification__progress-bar {
  background-color: red !important;
}
</style>
