/* eslint no-console: 0 */
/* eslint no-undef: "error" */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

import { groupMessagesBySender } from '../../shared/helpers/MessageGroupingHelpers';

export default {
  methods: {
    lastMessage(m) {
      return m.messages.last();
    },
    unreadMessagesCount(m) {
      return m.messages.filter(
        chat =>
          chat.created_at * 1000 > m.agent_last_seen_at * 1000 &&
          chat.message_type === 0 &&
          chat.private !== true
      ).length;
    },
    readMessages(m) {
      return groupMessagesBySender(
        m.messages.filter(
          chat => chat.created_at * 1000 <= m.agent_last_seen_at * 1000
        )
      );
    },
    unReadMessages(m) {
      return groupMessagesBySender(
        m.messages.filter(
          chat => chat.created_at * 1000 > m.agent_last_seen_at * 1000
        )
      );
    },
  },
};
