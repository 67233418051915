const notificationAudio = require('shared/assets/audio/ding.mp3');
const newChatAudio = require('shared/assets/audio/new-chat.mp3');
const newMessageAudio = require('shared/assets/audio/new-message.mp3');
const agentCallAudio = require('shared/assets/audio/new-message.mp3');

export const playNotificationAudio = () => {
  try {
    new Audio(notificationAudio).play();
  } catch (error) {
    console.log(error);
  }
};

export const playNewChatAudio = () => {
  try {
    new Audio('/packs/audio/new-chat.mp3').play();
  } catch (error) {
    console.log(error);
  }
};

export const playNewMessageAudio = () => {
  try {
    new Audio('/packs/audio/new-message.mp3').play();
  } catch (error) {
    console.log(error);
  }
};

const getAgentCallElement = () => {
  let audio = document.getElementById('agent-call');
  if (!audio) {
    audio = new Audio('/packs/audio/agent-call.mp3');
    audio.id = 'agent-call';
    audio.loop = true;
    audio.hidden = true;
    document.body.appendChild(audio);
  }
  return audio;
};

export const playAgentCallAudio = () => {
  try {
    let audio = getAgentCallElement();

    if (audio.paused) {
      audio.play();
    }
  } catch (error) {
    console.log(error);
  }
};

export const stopAgentCallAudio = () => {
  try {
    let audio = getAgentCallElement();
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }
  } catch (error) {
    console.log(error);
  }
};
